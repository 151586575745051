import React from "react";

const TermsContent = ({ title, data, para, parag }) => {
  return (
    <div className="m-10 max-w-7xl mx-auto md:px-4 sm:px-1">
      <h2 className="md:text-2xl sm:text-xl mt-10 md:px-3">{title}</h2>
      {para && <p className="md:px-5 px-1 mb-5 text-lg">{parag}</p>}
      <ol className="list-decimal md:px-16">
        {data?.map((li) => {
          return <li className="sm:mx-6 md:mx-0 my-2">{li}</li>;
        })}
      </ol>
    </div>
  );
};

export default TermsContent;
