import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div
        className="m-10 mb-0 max-w-7xl mx-auto px-4 sm:px-6"
        style={{ fontFamily: "Inter" }}
      >
        <div className="grid grid-cols-4 gap-4 md:grid-cols-2  sm:grid-cols-1 lg:grid-cols-2">
          <div className="rounded-xl place-items-center p-5 flex flex-col items-start justify-start w-50">
            <div className="px-3 text-2xl text-red-600 font-semibold">
              About Us
            </div>
            <div className=" px-3 mx-3 border1" />
            <p className="px-3 py-3">
              Maxx Fibernet is franchise of renowned Class A ISP World Phone
              Internet Services Pvt. Ltd Powered by WorldPhone
            </p>
          </div>
          <div className="rounded-xl place-items-center p-5 flex flex-col items-start justify-start w-50">
            <div className="px-3 text-2xl text-red-600 font-semibold">
              Useful Links
            </div>
            <div className=" px-3 mx-3 border1" />
            <ul className="mx-3">
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy & Policy</Link>
              </li>
              <li>
                <Link to="/terms">Cancellation Policy</Link>
              </li>
              {/* <li>
                <Link to="/contact">Contact Us</Link>
              </li> */}
            </ul>
          </div>
          {/* <div className="rounded-xl place-items-center p-5 flex flex-col items-start justify-start w-50">
            <div className="px-3 text-2xl text-red-600 font-semibold">
              Contact Info
            </div>
            <div className=" px-3 mx-3 border1" />
            <ul className="mx-3">
              <li>
                <MdOutlineLocationOn color="#dc2626" />
              </li>
              <li>
                <p>1.Karamatiya Market, Sarai Chowk, Jalalpur Ambedkar Nagar</p>
              </li>
              <li>
                <p className="mt-5">
                  2.Old Hospital Gali, Meeranpur, Akbarpur, Ambedkar Nagar
                </p>
              </li>
              <li>
                <span className="mt-8 flex items-center">
                  <MdPhone color="#dc2626" className="mr-2" />
                  <a href="tel:+917310444123">+917310444123</a>
                </span>
              </li>
              <li>
                <span className="mt-8 flex items-center">
                  <MdEmail color="#dc2626" className="mr-2" />
                  connect@maxxfibernet.in
                </span>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
