import React from "react";
import Footer from "../../Components/Footer";
import HeaderNav from "../../Components/HeaderNav";
import TermsContent from "./components/TermsContent";

const termsFiber = [
  "Optical Cables and Wires that is used during installation is belongs to Maxxfibernet.",
  "If issue at customer's end occur before 2:00 PM it will be resolved on sameday otherwise you have to wait till next working day.",
  "After one month customers will be charged 100 Rs per visit",
  "Customer should pay monthly bill bewteen 1st to 5th day of each month",
  "If bill is not paid within time connection will be deactivated. You will be charged 100 Rs for reactivation and you have to wait for upto 24hrs.",
  "Customer will charged for replacement of Patch card, JC Box, T Box and Lan cable if damaged.",
];
const termsAirfiber = [
  "All installation materials will be provided by Customer.",
  "Customers should turn off thier device during bad weather.",
  "After one month customers will be charged 100 Rs per visit",
  "If device is damaged with thunder or water guarantee will be automatically void.",
  "There will be no guarantee of Wireless device's adapter and Customer should provide 220 volts to wireless to wireless device adapter.",
  "If bill is not paid within time connection will be deactivated. You will be charged 100 Rs for reactivation and you have to wait for upto 24hrs.",
];
const disconnectionTerms = [
  "Maxx Fibernet retains the right to terminate this Agreement immediately in case of Customer's default under clause 1, 2,3,5,6,7,10 and 14 of Customer's Obligations.",
  "Any loss of business suffered by the Customer due to disconnection affected under clause a above will be his own responsibility and so borne by him. Disconnection under clause a above will not require any notice and no refund will be given to the Customer for unused portion of Service.",
];
const laws = [
  "Service is used in breach of terms /conditions in this Agreement.",
  "Service is faulty and cannot be used by a third Party.",
];

const forceMajeure = [
  "Acts of God, natural catastrophes, frequency interference from external source, epidemics, earthquakes;",
  "Strikes and blockades; either by Maxx Fibernet personnel or others affecting Service.",
  "War or war-like conditions, mobilization, revolutions or riots, acts of public enemy, sabotage, terrorism",
  "Restriction by or actions, omissions or interventions of Indian public authorities (including but not limited to changes in laws, regulations or import / export, security restrictions).",
  "Disconnection or deterioration of service by Maxx Fibernet vendor.",
];

const customerObligations = [
  "Understands that the Internet Services is governed by Internet Service Provider and other applicable licenses and laws of the Indian Government (Department of Telecommunications). Customer shall therefore ensure compliance to ALL such license conditions and laws and also ensure that Internet Services (here in after Services) provided by Maxx Fibernet . (herein after Maxx Fibernet) is not connected with PSTN (Public Switch Telephony Network). Any infringement would result in termination of Service without any notice. This will be in addition to any action taken by the Government, or under the Law. Understands further that the Internet contains unedited materials some of which are sexually explicit or may be offensive to some people. Customer accesses such material at his own risk. Maxx Fibernet has no control over and accepts no responsibility whatsoever for such materials.",
  "Shall also not use the services for any anti-national activity.",
  "Understands that customer is fully responsible for any misuse of IP's allotted to him by Maxx Fibernet.",
  "Understands further that under no circumstances shall Maxx Fibernet , its affiliates or its contractors be liable for any direct, indirect, incidental, special, punitive or consequential damages that result in any way from Customer's use of or inability to use Service or any part thereof, or Customers reliance on or use of information, services or merchandise provided on or through Service, or that results from mistakes, omission, interruption, errors, defects, delays in operations, or transmission, or any failure of performance.",
  "Will allow personnel of Maxx Fibernet or Vendor so authorized to install equipment as also lay necessary cables for the connectivity.",
  "Will arrange for installation of CPE (Customer Premises Equipment) at a safe place free from dust, humidity and heat, preferably in an air-conditioned environment in case that is provided by Maxx Fibernet.",
  "Shall allow necessary access for maintenance activities of such equipment whenever required by Maxx Fibernet at its premises.",
  "Arrange for un-interrupted and regulated (Sine-wave) power supply for equipment of specifications supplied by Maxx Fibernet , if the equipment has been installed by Maxx Fibernet.",
  "Shall pay the royalty to WPC and all other statutory fees or charges to make the service operational.",
  "Will not resell the bandwidth/connectivity provided by Maxx Fibernet until and unless otherwise agreed separately with Maxx Fibernet in writing as an addendum to this Agreement.",
  "Will pay to Maxx Fibernet for taking Service from them at the prescribed rate, as described on the face of this form. Rates may be revised from time to time. Customer will be informed in writing, through any means of communication possible, in case the rates are revised. Payment must be received by the due date to avoid suspension/ disconnection of the service. Non-receipt of the invoice by customer cannot be a reason for non-payment. The customer must contact the accounts department of Maxx Fibernet and obtain duplicate copies of the invoice, if required.",
  "Understands that the date of placement of order is deemed to be the date of this Agreement.",
  "Understands that it is the Customer's responsibility to get last mile connection from the nearest available source unless specifically mentioned in the front of this Agreement.",
  "Understands that the Customer will make payment, for Service, in favor of Maxx Fibernet, If the payment is made in cash, Customer must insist to have a proper/ valid receipt against cash payment and submit the same with Maxx Fibernet as and when requested by Maxx Fibernet . The Customer may make the payment also by purchasing vouchers available at Maxx Fibernet, Authorized Agents or websites.",
  "Understands that all hardware equipment including Access Equipment at Customerâ€™s Premises supplied by Maxx Fibernet would remain property of Maxx Fibernet and Customer will not prohibit authorized employees of Maxx Fibernet or Vendor so authorized to access, maintain and replace the equipment or any part there of installed inside Customer's premises. Customer undertakes not to obstruct removal of the equipment installed inside Customerâ€™s premises by Maxx Fibernet or Vendor so authorized on Maxx Fibernet written authorization on discontinuation / disconnection of services or termination of service under Exit Policy, since the same is the property of Maxx Fibernet.",
  "Understands that Customer Premises Equipment is third party equipment procured by Maxx Fibernet for providing connection to Customer. Maxx Fibernet shall make all efforts to get this equipment repaired at the earliest and at its sole discretion decide whether a defect in the equipment is to be repaired or the defective part is to be replaced. Customer undertakes not to obstruct or prohibit Maxx Fibernet or Vendor so authorized on Maxx Fibernet written authorization to take the equipment or any part of it from Customer premises thereof for rectification to its service center when deemed so necessary by Maxx Fibernet at its sole discretion. All defective parts after replacement and other replacements become the property of Maxx Fibernet, Customer having no right over them whatsoever.",
  "Shall undertake all necessary Government permissions, clearances and approvals to make the service operational beyond the Service provided by Maxx Fibernet.",
  "Understands that civil engineering works, if any, shall be the responsibility of Customer. Customer will ensure that necessary formalities for undertaking such works are obtained from the property owners and Government authorities, wherever necessary.",
  "Advance rental will be forfeited in case of any delay from customer's side related to power and space issue at BTS end.",
];
const Terms = () => {
  // Scroll to top when component mounts
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        {/* <HeaderNav /> */}
        <div className="m-10 max-w-7xl mx-auto px-4 sm:px-6">
          <h1 className="text-4xl font-semibold text-red-600">
            Terms & Conditions
          </h1>
          <p className="px-2 antialiased">Last Updated : 01 Jan 2022</p>
        </div>
        <TermsContent
          title="(A) Wired (FiberOptical) Terms & Conditions :"
          data={termsFiber}
        />
        <TermsContent
          title="(B) Wireless (Wi-Fi) Terms & Conditions :"
          data={termsAirfiber}
        />
        <TermsContent
          title="(C) Disconnection Terms :"
          data={disconnectionTerms}
        />
        <TermsContent
          title="(D) Applicable Laws :"
          data={laws}
          para={true}
          parag="The laws of India shall govern this Agreement. The parties shall submit to the exclusive jurisdiction of Courts in Lucknow. The Customer shall abide by all the applicable laws, rules and or regulations and shall keep Maxx Fibernet indemnified against any damage, loss and claims arising out of its negligent acts or omissions. The Customer must indemnify Maxx Fibernet against any claims or legal proceedings arising from provision of Service, which are brought against Maxx Fibernet by anyone using Service under or through Customer because:"
        />
        <TermsContent
          title="(E) Force Majeure :"
          data={forceMajeure}
          para={true}
          parag="Maxx Fibernet shall not be responsible or liable for any failure on its part to fulfill its obligations contained herein if such failure is on account of a force majeure event. Force Majeure shall mean such unforeseeable occurrences beyond the reasonable control of Maxx Fibernet such as:"
        />
        <TermsContent
          title="(F) Customer Obligations :"
          data={customerObligations}
          para={false}
        />
        <TermsContent
          title="(G) Exit Clause :"
          para={true}
          parag="This Agreement is valid for an initial term for one year and renewed automatically for a one year term on the expiry of the previous term unless terminated by Maxx Fibernet or Customer as per this clause and Disconnection Terms mentioned elsewhere in this Agreement. Maxx Fibernet can terminate this Agreement by giving one month notice. Customer may terminate this Agreement after the initial term by giving one month notice. Customer is liable to pay service fee for the balance of the term if he desires to terminate or terminates this Agreement. In addition, Customer is also liable to pay one month service fee if the Customer terminates the Agreement without providing adequate notice.
"
        />
      </div>
      <Footer />
    </>
  );
};

export default Terms;
